import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import CreditCard, {
  ICardRef,
} from "../../../../../components/TicketBooking/Payment/CreditCard";
import { URLS } from "../../../../../constants/url";
import {
  EncryptionKeyResponse,
  PAYMENT_TYPE,
} from "../../../../../models/payment";
import ReCAPTCHA from "react-google-recaptcha";
import {
  BRANDS,
  CAPTCHA_SITE_KEY,
  COUNTRY,
  PAYMENT_PROVIDERS,
  VACCINATION_LOCATIONS,
} from "../../../../../constants/app";
import {
  completeOrderApi,
  getCreditCardEncryptionKey,
  getPaymentMethod,
  makeGiftCardPaymentAPI,
  makePaymentApi,
  refundGiftCardApi,
  refundPayment,
} from "../../../../../services/payment";
import { encryptData } from "../../../../../utils/helper";
import {
  setModal,
  updateBookingId,
} from "../../../../../store/ticketBooking/action";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../../models/tickets";
import { TICKET_FLOW } from "../../../../../constants/ticket";
import GPay from "../../../../../components/TicketBooking/Payment/GPay";
import GiftCard from "../../../../../components/TicketBooking/Payment/GiftCard";
import ApplePay from "../../../../../components/TicketBooking/Payment/ApplePay";
import StripePayment from "../../../../../components/TicketBooking/Payment/Stripe";
import {
  MAKE_PAYMENT_REQUEST_TYPE,
  TOKEN_PAYMENT_REQUEST_TYPE,
} from "../../../../../constants/payment";
import {
  setContentModal,
  toggleContentModal,
} from "../../../../../store/application/action";
import { toast } from "react-toastify";
import { ordersApi } from "../../../../../services/concessions";
interface IPaymentProps {
  onCloseModal?: () => void;
}

const MembershipPayment: FC<IPaymentProps> = ({ onCloseModal }) => {
  const {
    countryId,
    brandId,
    modalType,
    prevModal,
    nextModal,
    currentCinema,
    isLoggedIn,
    userDetails,
    memberDetail,
    membershipPurchase,
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId as COUNTRY,
    brandId: state.applicationReducer.brandId,
    modalType: state.ticketBookingReducer.modal.type,
    prevModal: state.ticketBookingReducer.modal.prevModal,
    nextModal: state.ticketBookingReducer.modal.nextModal,
    currentCinema: state.applicationReducer.currentCinema,
    isLoggedIn: state.authReducer.isLoggedIn,
    userDetails: state.authReducer.userDetails,
    memberDetail: state.authReducer.memberDetail,
    membershipPurchase: state.authReducer.membershipPurchase,
  }));

  const dispatch = useDispatch();
  const cardRef = useRef<ICardRef>(null);
  const giftCardRef = useRef<ICardRef>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentType, setPaymentType] = useState(PAYMENT_TYPE.CARD);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
  const [informationStatus, setInformationStatus] = useState(false);
  const [showWallet, setShowWallet] = useState("");

  const total = useMemo(() => {
    return membershipPurchase &&
      membershipPurchase.loyaltyPackage &&
      membershipPurchase.loyaltyPackage.priceInCents
      ? membershipPurchase.loyaltyPackage.priceInCents / 100
      : 0;
  }, [membershipPurchase]);

  useEffect(() => {
    if (
      countryId !== COUNTRY.NZ &&
      VACCINATION_LOCATIONS.includes(currentCinema.slug)
    ) {
      setInformationStatus(true);
    } else {
      setInformationStatus(false);
    }

    if (
      countryId !== COUNTRY.NZ &&
      !/^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    ) {
      setShowWallet(PAYMENT_TYPE.GOOGLE_PAY);
    } else if (
      countryId !== COUNTRY.NZ &&
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    ) {
      const applepaySession = (window as any).ApplePaySession;
      if (applepaySession) {
        if (applepaySession.canMakePayments()) {
          setShowWallet(PAYMENT_TYPE.APPLE_PAY);
        }
      }
    }
  }, [countryId]);

  const toggleTab = (type: PAYMENT_TYPE) => {
    setErrorMessage("");
    setPaymentType(type);
  };

  const completeOrder = async ({
    cardType,
    isVoucher = false,
    isGivex = 0,
    giftCardPaidAmount = 0,
  }: any) => {
    let completeOrderPayload: any = {
      countryId: countryId,
      email: userDetails?.email,
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      loyaltyCardNumber:
        memberDetail &&
        memberDetail?.LoyaltyMember &&
        memberDetail?.LoyaltyMember?.CardNumber
          ? memberDetail.LoyaltyMember.CardNumber
          : "",
      paidamount: total,
      paymentAmountInCents: parseInt((Number(total) * 100).toFixed()),
      paymentCardType: cardType,
      phoneNumber: userDetails?.phoneNumber
        ? userDetails?.phoneNumber
        : memberDetail &&
          memberDetail?.LoyaltyMember &&
          memberDetail?.LoyaltyMember?.MobilePhone
        ? (memberDetail?.LoyaltyMember?.MobilePhone).replace("(", "")
            .replace(")", "")
            .replace("-", "")
            .replace(" ", "")
        : "",
      requestType: "completeOrder",
      saleId: userDetails?.UserSessionId || membershipPurchase?.userSessionId,
      userSessionId: userDetails?.UserSessionId || membershipPurchase?.userSessionId,
      isGivex: isGivex,
      giftCardPaidAmount: giftCardPaidAmount,
      isMemberPackage: true,
      insertId: userDetails?.id || (userDetails?.result?.memberRefId?(encryptData(`${userDetails?.result?.memberRefId}`, userDetails?.email)):'')
    };

    return await completeOrderApi(completeOrderPayload);
  };

  const makeCardPayment = async (
    processPayload: any,
    ptype: PAYMENT_TYPE,
    key: string,
    amount: number
  ) => {
    //Make payment
    const makePaymentPayload: any = {
      amount: getPaymentMethod(countryId) === PAYMENT_PROVIDERS.FATZEBRA?parseInt((amount).toFixed()):amount,
      email: userDetails?.email!,
      msgId: userDetails?.UserSessionId! || membershipPurchase?.userSessionId,
      saleId: userDetails?.UserSessionId! || membershipPurchase?.userSessionId,
      transactionReference: userDetails?.UserSessionId
        ? `invoice-sou-${userDetails?.UserSessionId}`
        : `invoice-sou-${membershipPurchase?.userSessionId}`,
      requestType: MAKE_PAYMENT_REQUEST_TYPE[countryId],
      countryId: countryId,
      memberRefId: userDetails?.result?.memberRefId || "",
      isMemberPackage: true,
      itemId:
        membershipPurchase &&
        membershipPurchase.loyaltyPackage &&
        membershipPurchase.loyaltyPackage.id
          ? membershipPurchase.loyaltyPackage.id
          : undefined,
    };

    //Checking payment type - new card, saved card, apple pay or google pay
    if (
      (processPayload.cardDetails && processPayload.action === "newCard") ||
      ptype === PAYMENT_TYPE.APPLE_PAY ||
      ptype === PAYMENT_TYPE.GOOGLE_PAY
    ) {
      const encryptKey =
        key + (userDetails?.UserSessionId || membershipPurchase?.userSessionId!);
      const encryptedData = encryptData(
        JSON.stringify(processPayload.cardDetails),
        encryptKey
      );
      makePaymentPayload.data = encryptedData;
      makePaymentPayload.saveCreditCard = processPayload?.saveCard;
    } else if (
      processPayload.cardDetails &&
      processPayload.action === "savedCard"
    ) {
      makePaymentPayload.tokenValue = processPayload.cardDetails.token;
      makePaymentPayload.requestType = TOKEN_PAYMENT_REQUEST_TYPE[countryId];
    }

    if (ptype === PAYMENT_TYPE.APPLE_PAY || ptype === PAYMENT_TYPE.GOOGLE_PAY) {
      makePaymentPayload.googleApplePay = true;
      makePaymentPayload.id = processPayload.paymentIntentId;
    }
    if (countryId === COUNTRY.ANG || countryId === COUNTRY.AUS || countryId === COUNTRY.STA) {
      makePaymentPayload.deviceId = (document.getElementById('pmnts_id') as any)?.value;
    }
    return await makePaymentApi(makePaymentPayload);
  };

  const refundCard = async (
    txnID: string,
    transactionReference: string,
    amount: number
  ) => {
    const refundDetails: any = {
      amount: getPaymentMethod(countryId) === PAYMENT_PROVIDERS.FATZEBRA?parseInt((amount).toFixed()):amount,
      countryId: countryId,
      txnType: 4,
      requestType: "refundPayment",
      paymentMethod: getPaymentMethod(countryId),
      txnID: txnID,
      transactionReference: transactionReference,
      saleId: userDetails?.UserSessionId || membershipPurchase?.userSessionId,
      isMemberPackage: true
    };
    return await refundPayment(refundDetails);
  };

  const processPayment = async (processPayload: any, ptype: PAYMENT_TYPE) => {
    const decryptPayload: any = {
      countryId: countryId,
      saleId: userDetails?.UserSessionId! || membershipPurchase?.userSessionId,
      requestType: "isMemberPackage",
    };

    getCreditCardEncryptionKey(
      decryptPayload,
      async (data: EncryptionKeyResponse | null) => {
        if (data) {
          //order api
          if (
            membershipPurchase?.membershipType === "upgrade" ||
            membershipPurchase?.membershipType === "renewal"
          ) {
            const {
              data: { success },
            } = await ordersApi({
              cinemaId: currentCinema.slug,
              cinemaName: currentCinema.slug,
              userSessionId: membershipPurchase?.userSessionId,
              countryId: countryId,
              transactionAmount:
                membershipPurchase?.loyaltyPackage?.priceInCents,
              isMobile: 0,
              userAgent: navigator.userAgent,
              itemId: membershipPurchase?.loyaltyPackage?.id,
              recognitionId: membershipPurchase?.loyaltyPackage?.recognitionId,
              isMemberPackage: true,
            });
            if (!success) {
              toast.error("Failed! Unable to upgrade the membership package");
              return;
            }
          }
          //const ticketAmount = getPaymentMethod() === "fatzebra"?
          //(Math.round((ticketDetails?.total ? ticketDetails.total : 0) + concessionTotal) *
          //100) //Testing only
          const ticketAmount =
            getPaymentMethod(countryId) === PAYMENT_PROVIDERS.FATZEBRA ||
            getPaymentMethod(countryId) === PAYMENT_PROVIDERS.PAYEEZY
              ? Number(total * 100)
              : Number(total);

          const makePaymentResponse: any = await makeCardPayment(
            processPayload,
            ptype,
            data.data.key,
            ticketAmount
          );
          if (makePaymentResponse.status) {
            const completeOrderResponse: any = await completeOrder({
              cardType: "ccard",
            });
            if (completeOrderResponse.status) {
              dispatch(updateBookingId(completeOrderResponse.VistaBookingId));
              onNext();
            } else {
              refundCard(
                makePaymentResponse.data.txnID,
                makePaymentResponse.data.transactionReference,
                getPaymentMethod(countryId) === PAYMENT_PROVIDERS.FATZEBRA ||
                  getPaymentMethod(countryId) === PAYMENT_PROVIDERS.PAYEEZY
                  ? Number(total) * 100
                  : Number(total)
              );
              onError(completeOrderResponse?.error);
            }
          } else {
            onError(makePaymentResponse?.error);
          }
        }
      }
    );
  };

  const onNext = () => {
    const ticketType: TICKET_FLOW_TYPES = modalType;
    const next: MODAL_TYPE = nextModal;
    const modal = TICKET_FLOW[ticketType][next];
    dispatch(
      setModal({
        ...modal,
        type: modalType,
      })
    );
  };

  const onError = (errorMsg?: string) => {
    const ticketType: TICKET_FLOW_TYPES = modalType;
    const next: MODAL_TYPE = nextModal;
    const modal = TICKET_FLOW[ticketType][MODAL_TYPE.MEMBERSHIP_ERROR];
    dispatch(
      setModal({
        ...modal,
        type: modalType,
        errorMsg,
      })
    );
  };

  const onCardPayment = async () => {
    const cardDetails: any = cardRef?.current?.submit();
    if (!cardDetails) {
      return;
    }
    const { card, action, saveCard } = cardDetails;
    if (card && !isTermsChecked && !isLoggedIn) {
      setErrorMessage("Please agree Terms and conditions");
      return;
    }

    if (!isCaptchaChecked) {
      setErrorMessage("Verification Code is required");
      return;
    }

    if (action === "newCard") {
      processPayment(
        { cardDetails: card, action, saveCard },
        PAYMENT_TYPE.CARD
      );
    } else if (action === "savedCard") {
      processPayment({ cardDetails: card, action }, PAYMENT_TYPE.CARD);
    }
  };

  const onGiftCard = async () => {
    const giftCardDetails = giftCardRef?.current?.submit();
    if (!giftCardDetails) {
      return;
    }
    if (giftCardDetails && !isTermsChecked && !isLoggedIn) {
      setErrorMessage("Please agree Terms and conditions");
      return;
    }

    if (!isCaptchaChecked) {
      setErrorMessage("Verification Code is required");
      return;
    }

    const makeRefund = async (payload: any) => {
      return await refundGiftCardApi(payload);
    };

    let paymentSuccess = true;
    let payableGiftCardAmt = 0;
    let params: any = [];
    for (let card of giftCardDetails.giftCards) {
      let payload: any = {
        countryId: countryId,
        requestType: "giftcardRedemption",
        paymentMethod: "vista",
        saleId: userDetails?.UserSessionId || membershipPurchase?.userSessionId,
        cardNumber: card.card,
        transactionReference:
          userDetails?.UserSessionId || membershipPurchase?.userSessionId,
        amount: card.balance,
        totalAmount: giftCardDetails.total,
      };
      const response: any = await makeGiftCardPaymentAPI(payload);
      if (response.status) {
        payableGiftCardAmt = payableGiftCardAmt + card.balance;
        payload["paymentStatus"] = "success";
        payload["ticketSalesPaidStatus"] = 1;
        payload["txnReference"] = response.transactionCode;
      } else {
        payload["paymentStatus"] = "failure";
        payload["ticketSalesPaidStatus"] = 0;
        paymentSuccess = false;
      }
      params.push(payload);
    }

    if (paymentSuccess) {
      //order api
      if (
        membershipPurchase?.membershipType === "upgrade" ||
        membershipPurchase?.membershipType === "renewal"
      ) {
        const {
          data: { success },
        } = await ordersApi({
          cinemaId: currentCinema.slug,
          cinemaName: currentCinema.slug,
          userSessionId: membershipPurchase?.userSessionId,
          countryId: countryId,
          transactionAmount:
            membershipPurchase?.loyaltyPackage?.priceInCents,
          isMobile: 0,
          userAgent: navigator.userAgent,
          itemId: membershipPurchase?.loyaltyPackage?.id,
          recognitionId: membershipPurchase?.loyaltyPackage?.recognitionId,
          isMemberPackage: true,
        });
        if (!success) {
          toast.error("Failed! Unable to upgrade the membership package");
          return;
        }
      }
      if (giftCardDetails.enableCreditCard) {
        getCreditCardEncryptionKey(
          {
            countryId: countryId,
            saleId: userDetails?.UserSessionId! || membershipPurchase?.userSessionId!,
          },
          async (data: EncryptionKeyResponse | null) => {
            if (data) {
              const makePaymentResponse: any = await makeCardPayment(
                {
                  cardDetails: giftCardDetails.cardDetails,
                  action: giftCardDetails.action,
                  saveCard: giftCardDetails.saveCard,
                },
                PAYMENT_TYPE.CARD,
                data.data.key,
                getPaymentMethod(countryId) === PAYMENT_PROVIDERS.FATZEBRA ||
                  getPaymentMethod(countryId) === PAYMENT_PROVIDERS.PAYEEZY
                  ? giftCardDetails.balance * 100
                  : giftCardDetails.balance
              );
              if (makePaymentResponse.status) {
                const completeOrderResponse: any = await completeOrder({
                  cardType: "ccard",
                  isGivex: 1,
                  giftCardPaidAmount: payableGiftCardAmt,
                });
                if (completeOrderResponse.status) {
                  await dispatch(
                    updateBookingId(completeOrderResponse.VistaBookingId)
                  );
                  onNext();
                } else {
                  for (let param of params) {
                    await makeRefund(param);
                  }
                  await refundCard(
                    makePaymentResponse.data.txnID,
                    makePaymentResponse.data.transactionReference,
                    getPaymentMethod(countryId) ===
                      PAYMENT_PROVIDERS.FATZEBRA ||
                      getPaymentMethod(countryId) === PAYMENT_PROVIDERS.PAYEEZY
                      ? giftCardDetails.balance * 100
                      : giftCardDetails.balance
                  );
                  onError(completeOrderResponse?.error);
                }
              } else {
                onError(makePaymentResponse.error);
              }
            }
          }
        );
      } else {
        const completeOrderResponse: any = await completeOrder({
          cardType: "givex",
          isGivex: 1,
          giftCardPaidAmount: payableGiftCardAmt,
        });
        if (completeOrderResponse.status) {
          await dispatch(updateBookingId(completeOrderResponse.VistaBookingId));
          onNext();
        } else {
          for (let param of params) {
            await makeRefund(param);
          }
          onError(completeOrderResponse?.error);
        }
      }
    } else {
      for (let param of params) {
        await makeRefund(param);
      }
      onError();
    }
  };

  const onPurchase = () => {
    switch (paymentType) {
      case PAYMENT_TYPE.CARD:
        onCardPayment();
        break;
      case PAYMENT_TYPE.GIFT_CARD:
        onGiftCard();
        break;
    }
  };

  const onChangeCaptcha = (event: any) => {
    if (event) {
      setIsCaptchaChecked(true);
      setErrorMessage("");
    } else {
      setIsCaptchaChecked(false);
      setErrorMessage("Verification Code is required");
    }
  };

  const onCheckTerms = (event: any) => {
    const {
      target: { checked },
    } = event;
    setIsTermsChecked(checked);
  };

  const isValid = useMemo(() => {
    if (paymentType === PAYMENT_TYPE.GOOGLE_PAY) {
      if (!isTermsChecked) {
        return false;
      } else if (!isCaptchaChecked) {
        return false;
      }
    }
    setErrorMessage("");
    setErrorMessage("");
    return true;
  }, [isTermsChecked, isCaptchaChecked, paymentType]);

  const checkValid = () => {
    if (!isTermsChecked) {
      setErrorMessage("Please agree Terms and conditions");
      return false;
    } else if (!isCaptchaChecked) {
      setErrorMessage("Verification Code is required");
      return false;
    }
  };

  const openTerms = () => {
    dispatch(
      setContentModal({
        type: "Rewards Etix Terms",
      })
    );
    dispatch(toggleContentModal(true));
  };

  return (
    <div className="row ticket-payment-wrapper">
      <div className="col-12 auth-wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="row no-gutters pay-method">
              <div className="col-12 col-md-12">
                <div className="form_page pay_heading">
                  <div className="pay_method_icons">
                    <ul className="nav nav-tabs">
                      <li
                        className={`nav-item icons ${
                          paymentType === PAYMENT_TYPE.CARD ? "active" : ""
                        }`}
                        onClick={() => toggleTab(PAYMENT_TYPE.CARD)}
                      >
                        <a
                          className="nav-link active"
                          href="javascript:void(0);"
                        >
                          <span className="credit_card ">Credit Card</span>
                        </a>
                      </li>
                      {showWallet === PAYMENT_TYPE.GOOGLE_PAY ? (
                        <li
                          className={`nav-item icons ${
                            paymentType === PAYMENT_TYPE.GOOGLE_PAY
                              ? "active"
                              : ""
                          }`}
                          onClick={() => toggleTab(PAYMENT_TYPE.GOOGLE_PAY)}
                        >
                          <a className="nav-link" href="javascript:void(0);">
                            <span className="wallet_pay gpay_cls">
                              <img
                                alt="GPayIcon"
                                className="gpayBtn"
                                src={URLS.GOOGLE_PAY_MARK}
                              />
                            </span>
                          </a>
                        </li>
                      ) : null}
                      {showWallet === PAYMENT_TYPE.APPLE_PAY ? (
                        <li
                          className={`nav-item icons ${
                            paymentType === PAYMENT_TYPE.APPLE_PAY
                              ? "active"
                              : ""
                          }`}
                          onClick={() => toggleTab(PAYMENT_TYPE.APPLE_PAY)}
                        >
                          <a className="nav-link" href="javascript:void(0);">
                            <span className="applePay_cls">
                              <img
                                src={URLS.APPLE_PAY_ICON}
                                alt="ApplePayIcon"
                              />
                              Apple Pay
                            </span>
                          </a>
                        </li>
                      ) : null}

                      <li
                        className={`nav-item icons gift_Card_tab ${
                          paymentType === PAYMENT_TYPE.GIFT_CARD ? "active" : ""
                        }`}
                        onClick={() => toggleTab(PAYMENT_TYPE.GIFT_CARD)}
                      >
                        <a className="nav-link" href="javascript:void(0);">
                          <span className="gift_Card">Gift Card</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  {paymentType === PAYMENT_TYPE.CARD ? (
                    <CreditCard ref={cardRef} fromPage="membership" />
                  ) : null}
                  {paymentType === PAYMENT_TYPE.GIFT_CARD ? (
                    <GiftCard ref={giftCardRef} fromPage="membership" />
                  ) : null}
                </div>
              </div>
              <div className="col-12 mt-4">
                <div className="custom_checkbox option_link">
                  <input
                    type="checkbox"
                    value="1"
                    checked={isTermsChecked}
                    onClick={onCheckTerms}
                  />
                  <div className="state p-primary">
                    <label className="form-label">
                      <div>
                        I have read and understand the{" "}
                        {countryId === COUNTRY.STA
                          ? "State Cinema"
                          : countryId === COUNTRY.ANG
                          ? "Angelika"
                          : "Reading"}{" "}
                        Internet Ticket Sales{" "}
                        <span className="non_atag_link_u" onClick={openTerms}>
                          Terms and conditions
                        </span>{" "}
                        {informationStatus ? (
                          <span>
                            including vaccination requirements as outlined above
                          </span>
                        ) : null}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12  col-md-12">
                  <label className="form-label">Verification Code* </label>
                  <div className="form-group">
                    <ReCAPTCHA
                      sitekey={`${CAPTCHA_SITE_KEY}`}
                      onChange={onChangeCaptcha}
                    />
                  </div>
                </div>
              </div>
              {errorMessage ? (
                <div className="error_message text-center">
                  <span>{errorMessage}</span>
                </div>
              ) : null}

              <div>
                <div className="user-details">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="total">TOTAL PURCHASE</div>
                      <div className="total-amount">${total.toFixed(2)}</div>
                      {membershipPurchase &&
                      membershipPurchase.loyaltyPackage &&
                      membershipPurchase.loyaltyPackage?.description ? (
                        <p className="total">
                          1 x {membershipPurchase.loyaltyPackage?.description}:
                          $
                          {(
                            membershipPurchase.loyaltyPackage.priceInCents / 100
                          ).toFixed(2)}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 movie_footer_btn_el">
                <div className="movie_button_wrap">
                  <button
                    type="button"
                    className="btn gray_btn"
                    // onClick={onCancelTicket}
                  >
                    BACK
                  </button>
                  {paymentType === PAYMENT_TYPE.APPLE_PAY &&
                  countryId !== COUNTRY.NZ &&
                  brandId == BRANDS.AU ? (
                    <ApplePay
                      processPayment={processPayment}
                      isValid={isValid}
                      onError={onError}
                      checkValid={checkValid}
                      page={"membership"}
                    />
                  ) : paymentType === PAYMENT_TYPE.GOOGLE_PAY &&
                    countryId !== COUNTRY.NZ &&
                    brandId == BRANDS.AU ? (
                    <GPay
                      processPayment={processPayment}
                      isValid={isValid}
                      onError={onError}
                      checkValid={checkValid}
                      page={"membership"}
                    />
                  ) : (paymentType === PAYMENT_TYPE.APPLE_PAY ||
                      paymentType === PAYMENT_TYPE.GOOGLE_PAY) &&
                    countryId === COUNTRY.NZ &&
                    brandId == BRANDS.AU ? (
                    <StripePayment
                      processPayment={processPayment}
                      isValid={isValid}
                      onError={onError}
                      checkValid={checkValid}
                    />
                  ) : (
                    <button
                      type="button"
                      className="btn black_btn"
                      onClick={onPurchase}
                    >
                      PURCHASE
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipPayment;
