import React, { FC, useEffect, useState } from "react";
import Modal from "../../../../components/UI/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { URLS } from "../../../../constants/url";
import Member from "../../../../components/Auth/Member";
import SignUp from "../../../../components/Auth/SignUp";
import "./auth.scss";
import { showLogin } from "../../../../store/auth/action";
import { BRANDS, COUNTRY } from "../../../../constants/app";
import MemberSignup from "../../../../components/Auth/MemberSignup";
import SignupBanner from "../../../../components/Auth/Membership/SignupBanner";

interface IAuthProps {}

const Auth: FC<IAuthProps> = () => {
  const { countryId, openModal, isLoggedIn, brandId, openSlider } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      brandId: state.applicationReducer.brandId,
      openModal: state.authReducer.openModal,
      isLoggedIn: state.authReducer.isLoggedIn,
      openSlider: state.applicationReducer.openSlider
    })
  );

  const dispatch = useDispatch();
  const [tab, setTab] = useState("sign-in");
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    setTab("sign-in");
    setShowBanner(countryId === COUNTRY.ANG?true:false);
  }, [openModal]);

  const onCloseModal = () => {
    dispatch(showLogin(false));
    setShowBanner(countryId === COUNTRY.ANG?true:false);
  };

  const changeTab = (type: string) => {
    setTab(type);
    setShowBanner(countryId === COUNTRY.ANG?true:false);
  };

  const onSignup = () => {
    dispatch(showLogin(true));
    setShowBanner(false);
  };

  return (
    <Modal
      className="auth-modal"
      visible={openModal && !isLoggedIn}
      showCloseBtn={false}
    >
      <div className="modal fade show" style={{ display: "block" }}>
        <div
          className={`modal-dialog modal-dialog-centered ${
            tab === "sign-up" && brandId === BRANDS.US ? "modal-lg" : "modal-md"
          }`}
        >
          <div className="modal-content">
            <button
              type="button"
              className="close-modal"
              onClick={onCloseModal}
            >
              <img
                className="close_icon"
                src={URLS.CLOSE_ICON}
                alt="close_icon"
                title="close_icon"
              />
            </button>
            <div className="modal-header">
              <div className="modal-title">
                {brandId === BRANDS.US ? "User Sign Up" : "User Login"}
              </div>
            </div>
            <div className="modal-body">
              <div className="col-12 auth-wrapper">
                <div className="d-flex">
                  <div
                    onClick={() => changeTab("sign-in")}
                    className={`login-tab ${tab === "sign-in" ? "active" : ""}`}
                  >
                    {brandId === BRANDS.US ? "LOG IN" : "Sign-in"}
                  </div>
                  <div
                    onClick={() => changeTab("sign-up")}
                    className={`login-tab ${tab === "sign-up" ? "active" : ""}`}
                  >
                    {brandId === BRANDS.US ? "SIGN UP" : "Sign-up"}
                  </div>
                </div>
                <div>
                  <div className="my-4">
                    {tab === "sign-in" ? <Member fromPage={openSlider?.type?openSlider?.type:'account'} /> : null}
                  </div>
                  <div className="my-4">
                    {tab === "sign-up" ? (
                      showBanner ? (
                        <SignupBanner
                          onSignup={onSignup}
                          onCancel={onCloseModal}
                        />
                      ) : (
                        <SignUp />
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Auth;
