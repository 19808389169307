import { FC, useMemo } from 'react';
import CommonHeader from '../CommonHeader';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';
import { IMAGE_URL_CONFIG } from '../../../../constants/url';
import { formatMembershipExpireDate } from '../../../../utils/rewards';
import "./reward.scss";
import moment from 'moment';

interface IRewardsHeaderProps { }

const RewardsHeader: FC<IRewardsHeaderProps> = () => {
    const {
        memberDetail,
        membershipPurchase,
    } = useSelector((state: RootState) => ({
        memberDetail: state.authReducer.memberDetail,
        membershipPurchase: state.authReducer.membershipPurchase,
    }));
    console.log('membershipPurchase', membershipPurchase)

    const expireDate = useMemo(() => {
        return formatMembershipExpireDate(memberDetail!)
    }, [memberDetail])

    return <div className='reward_header'>
        <h4 className="membership-header">Digital Loyalty Membership Payment</h4>
        <div className='reward_header_wrapper'>
            <div className="header">
                <div className="logo">
                    <img
                        src={
                            `${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}angelika_rewards_gold.svg`
                        }
                    /></div>
                <div className="member_detail">
                    <div className="member_label">{memberDetail?.LoyaltyMember?.CardNumber?'Member #':''}</div>
                    <div className="member-no">
                        {memberDetail?.LoyaltyMember?.CardNumber}
                    </div>
                </div>
            </div>
            <div className='body'>
                <h4>{membershipPurchase?.loyaltyPackage?.description}</h4>
                {membershipPurchase?.membershipType === 'renewal' ? <><p>Current Expiry Date: <span>{expireDate}</span></p>
                    <p>New Expiry Date: <span>{moment(expireDate,'DD-MM-YYYY').add(1, 'year').format('DD-MM-YYYY')}</span></p></> : null}
                {membershipPurchase?.membershipType === 'upgrade' ?
                    <p>Expiry Date: <span>{moment().add(1, 'year').format('DD MMM YYYY')}</span></p> : null}
                <p>Annual membership subscription fee: <span>${(membershipPurchase?.loyaltyPackage?.priceInCents / 100).toFixed(2)}</span></p>
            </div>
        </div>
        <h4 className="membership-header">Payment Details</h4>
    </div>;
}

export default RewardsHeader;