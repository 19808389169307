import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  clearFoodItemsAction,
  setTicketLessFb,
} from "../../../store/foodAndBeverage/action";
import {
  getTicketBookingDetails,
  setModal,
  setSeatSwapDetails,
  toggleTicketModal,
} from "../../../store/ticketBooking/action";
import { RootState } from "../../../store";
import { setCinemaAction } from "../../../store/application/action";
import { findCinemaObject } from "../../../utils/formatter";
import { TICKET_FLOW } from "../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../models/tickets";
import { AES_KEY } from "../../../constants/app";
import { decryptData } from "../../../utils/helper";
import { getTicketSalesDataApi } from "../../../services/films";
import { API_REQUEST_TYPE } from "../../../constants/url";

interface ITicketingProps {
  type: string;
}

const Ticketing: FC<ITicketingProps> = ({ type }) => {
  const {userDetails, isLoggedIn, currentCinema, countryId, cinemas } = useSelector(
    (state: RootState) => ({
      userDetails: state.authReducer.userDetails,
      isLoggedIn: state.authReducer.isLoggedIn,
      cinemas: state.applicationReducer.cinemas,
      currentCinema: state.applicationReducer.currentCinema,
      countryId: state.applicationReducer.countryId,
    })
  );

  const {
    cinemaId: cinemaAlias,
    sessionId,
    movieId,
    sharedUserSessionId,
  } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (cinemaAlias) {
      const cinema = findCinemaObject(cinemas, cinemaAlias);
      if (cinema) {
        dispatch(setCinemaAction(cinema));
      }
    }
  }, [cinemaAlias]);

  const getTicketSales = async() => {
    const { data :{ data}} = await getTicketSalesDataApi({
      requestType: API_REQUEST_TYPE.GET_SALES_DATA,
      userSessionId: sharedUserSessionId,
      countryId: countryId,
    });

    if(data){
      dispatch(setTicketLessFb(false));
      dispatch(clearFoodItemsAction());
      dispatch(
        getTicketBookingDetails({
          cinemaId: data.cinemaId,
          sessionId: data.sessionId,
          reservedSeating: "",
          screenType: "",
          countryId: countryId,
          covidFlag: currentCinema.covidFlag,
        })
      );
      dispatch(toggleTicketModal(true));
      dispatch(setSeatSwapDetails(data));
    }
  };

  useEffect(() => {
    if (
      type === "refund" &&
      currentCinema &&
      currentCinema?.alias?.toLowerCase() === cinemaAlias?.toLowerCase() &&
      currentCinema?.slug &&
      sharedUserSessionId
    ) {
      const modal = TICKET_FLOW[TICKET_FLOW_TYPES.REFUND][MODAL_TYPE.REFUND];
      dispatch(
        setModal({
          ...modal,
          type: MODAL_TYPE.REFUND,
          data: decryptData(sharedUserSessionId, AES_KEY),
        })
      );
      dispatch(toggleTicketModal(true));
    }

    else if (
      type === "swapSeat" &&
      currentCinema &&
      currentCinema?.alias?.toLowerCase() === cinemaAlias?.toLowerCase() &&
      currentCinema?.slug &&
      sharedUserSessionId
    ) {
      dispatch(toggleTicketModal(false));
      getTicketSales();
    }else if(
    sessionId &&
    movieId &&
    currentCinema &&
    currentCinema?.alias === cinemaAlias) {
      dispatch(setTicketLessFb(false));
      dispatch(clearFoodItemsAction());
      dispatch(
        getTicketBookingDetails({
          cinemaId: currentCinema.slug,
          sessionId: sessionId,
          reservedSeating: "",
          screenType: "",
          countryId: countryId,
          covidFlag: currentCinema.covidFlag,
          sharedUserSessionId: sharedUserSessionId
            ? decodeURIComponent(sharedUserSessionId)
            : undefined,
            isLoggedIn:isLoggedIn
        })
      );
      dispatch(toggleTicketModal(true));
    }
  }, [currentCinema, sessionId, movieId, currentCinema, sharedUserSessionId]);

  return null;
};
export default Ticketing;
