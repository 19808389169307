import { toast } from "react-toastify";
import { TICKET_FLOW } from "../../constants/ticket";
import { ILoyaltyPackage } from "../../models/auth";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../models/tickets";
import { getSettingsSuccess } from "../../store/application/action";
import { memberValidationWatcher, updateMembbershipPurchase } from "../../store/auth/action";
import { setModal, toggleTicketModal } from "../../store/ticketBooking/action";
import { generateUsersessionId } from "../../utils/tickets";
import { getSettingsApi } from "../application";
import { getMemberItemsApi, getMembershipPackageApi } from "../auth";

const getSettings = async (countryId: string, dispatch: any) => {
    const { status, response, error } = await getSettingsApi({ countryId }) as any;
    if (status) {
        dispatch(getSettingsSuccess(response.data.data.settings))
    }
    return response?.data?.data?.settings
}

const getMembershipPackage = async (countryId: string) => {
    const {
        data: {
            data: { loyaltyMembershipPackages },
            status,
        },
    } = await getMembershipPackageApi({
        countryId,
        cinemaId: '0000000001'
    });

    if (loyaltyMembershipPackages && loyaltyMembershipPackages.length > 0) {
        return loyaltyMembershipPackages;
    }
}

export const renewMembership = async (countryId: any, currentCinema: any, userDetails: any, dispatch: any) => {
    const settings = await getSettings(countryId, dispatch);
    const {
        data: {
            data: {
                data: { ConcessionList },
            },
        },
    } = await getMemberItemsApi({
        countryId,
        MemberRefId: userDetails?.vista_memberid!,
        cardNumber: userDetails?.cardNumber!,
        isMemberPackage: true,
    });
    if (ConcessionList && ConcessionList.length > 0) {
        const renewalData = ConcessionList.find(
            (v: any) =>
                Number(v.RecognitionID) ===
                settings?.membership?.renewal?.recognitionId
        )
        if(!renewalData){
            toast.error("Unable to renew membership")
            return;
        }
        const userSessionId = generateUsersessionId(currentCinema?.slug);
        dispatch(
            updateMembbershipPurchase({
                membershipType: "renewal",
                userSessionId: userSessionId,
                loyaltyPackage: {
                    description: renewalData?.Name,
                    extendedDescription: renewalData?.Description,
                    id: settings?.membership?.renewal?.itemId,
                    recognitionId: settings?.membership?.renewal?.recognitionId,
                    priceInCents: settings?.membership?.renewal?.priceInCents,
                },
            })
        );
        const payload = {
            MemberRefId: userDetails?.vista_memberid,
            UserSessionId: userSessionId,
            cardNumber: userDetails?.result?.cardNumber,
            countryId: countryId,
        };

        dispatch(
            memberValidationWatcher(
                payload,
                (res: any) => {
                    dispatch(
                        setModal({
                            ...TICKET_FLOW[TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE][
                            MODAL_TYPE.MEMBERSHIP_PAYMENT
                            ],
                            type: TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE,
                        })
                    );
                },
                (err: any) => { }
            )
        );
        dispatch(toggleTicketModal(true));
    }
}

export const upgrademembership = async (countryId: any, currentCinema: any, userDetails: any, dispatch: any) => {

    const settings = await getSettings(countryId, dispatch);
    const {
        data: {
            data: {
                data: { ConcessionList },
            },
        },
    } = await getMemberItemsApi({
        countryId,
        MemberRefId: userDetails?.vista_memberid!,
        cardNumber: userDetails?.cardNumber!,
        isMemberPackage: true,
    });
    if (ConcessionList && ConcessionList.length > 0) {
        const upgradeData = ConcessionList.find(
            (v: any) =>
                Number(v.RecognitionID) ===
                settings?.membership?.upgrade?.recognitionId &&
                Number(v.VistaID) === settings?.membership?.upgrade.itemId
        )

        const userSessionId = generateUsersessionId(currentCinema?.slug);
        dispatch(
            updateMembbershipPurchase({
                membershipType: "upgrade",
                userSessionId: userSessionId,
                loyaltyPackage: {
                    description: upgradeData?.Name,
                    extendedDescription: upgradeData?.Description,
                    id: settings?.membership?.upgrade?.itemId,
                    recognitionId: settings?.membership?.upgrade?.recognitionId,
                    priceInCents: settings?.membership?.upgrade?.priceInCents,
                },
            })
        );
        const payload = {
            MemberRefId: userDetails?.vista_memberid,
            UserSessionId: userSessionId,
            cardNumber: userDetails?.result?.cardNumber,
            countryId: countryId,
        };
        dispatch(
            memberValidationWatcher(
                payload,
                (res: any) => {
                    dispatch(
                        setModal({
                            ...TICKET_FLOW[TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE][
                            MODAL_TYPE.MEMBERSHIP_PAYMENT
                            ],
                            type: TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE,
                        })
                    );
                },
                (err: any) => { }
            )
        );
        dispatch(toggleTicketModal(true));
    }
}

export const newMembership = async (countryId: any, currentCinema: any, dispatch: any) => {
    const packages = await getMembershipPackage(countryId);
    const settings = await getSettings(countryId, dispatch);
    const userSessionId = generateUsersessionId(currentCinema?.slug);
    const loyaltyPackage = packages.find((v: ILoyaltyPackage) => {
        return (
            v.activationData &&
            Number(v.activationData.id) === Number(settings?.membership?.new?.itemId)
        );
    });
    dispatch(
        updateMembbershipPurchase({
            userSessionId: userSessionId,
            membershipType: "new",
            clubId: loyaltyPackage ? loyaltyPackage.clubId : 0,
            loyaltyPackage: loyaltyPackage
                ? loyaltyPackage.activationData
                : null,
        })
    );
    dispatch(
        setModal({
            ...TICKET_FLOW[TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE][
            MODAL_TYPE.MEMBERSHIP_AUTH
            ],
            type: TICKET_FLOW_TYPES.MEMBERSHIP_PURCHASE,
        })
    );
    dispatch(toggleTicketModal(true));
}