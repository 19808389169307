import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { setContentModal, toggleContentModal } from '../../../../../store/application/action';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../../../constants/path';
import { IMAGE_URL_CONFIG } from '../../../../../constants/url';
import './activateAccountInfo.scss'

interface IActivateAccountProps { 
    onCloseModal: () => void;
}

const ActivateAccount: FC<IActivateAccountProps> = ({onCloseModal}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const returnHome = () => {
        onCloseModal();
        navigate(`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`);
    }

    return <div className="activate-account-info-wrapper">
        <div className="col-12">
            <div className="content">
                <div className="header">
                    <h2>You are yet to activate your account.</h2>
                </div>

                <div className="body">
                    <div className="activation_link_banner">
                        <div className="party_logo">
                            <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'envelope-gold.svg'} />
                        </div>
                        <div className="activation_content">
                            <p>
                                <span>An activation link has been sent to your registered email address.</span>
                                <br />Please click on the activation link in your email<span>
                                    {" "} to start using your exclusive Angelika Rewards benefits.</span>
                            </p>
                        </div>
                    </div>
                    <div className={`movie_footer_btn_el col-md-12 ml-auto`}>
                        <div className="movie_button_wrap justify-content-end">
                            <button
                                type="button"
                                className="btn black_btn"
                                onClick={returnHome}>
                                RETURN TO HOME PAGE                             </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
}

export default ActivateAccount;